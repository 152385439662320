var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        fullscreen: "",
        "hide-overlay": "",
        transition: "dialog-bottom-transition"
      },
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-card",
        { staticClass: "new-tt" },
        [
          _c(
            "v-toolbar",
            { attrs: { dark: "", color: "primary", height: "48", fixed: "" } },
            [
              _c(
                "v-btn",
                {
                  attrs: { icon: "", dark: "" },
                  on: {
                    click: function($event) {
                      return _vm.close()
                    }
                  }
                },
                [_c("v-icon", [_vm._v("close")])],
                1
              ),
              _c("v-toolbar-title", [_vm._v("New Ticket")])
            ],
            1
          ),
          _c(
            "v-container",
            { staticClass: "panel-wrapper", attrs: { "grid-list-xl": "" } },
            [
              _c(
                "div",
                { staticStyle: { "padding-top": "48px" } },
                [
                  _c(
                    "v-layout",
                    { attrs: { column: "" } },
                    [
                      _c(
                        "v-flex",
                        [
                          _c("p", [
                            _vm._v(
                              "Thank you for contacting Cloud Clusters. Providing adequate information or context will help us investigate your issue faster."
                            )
                          ]),
                          _c("v-divider")
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        [
                          _c(
                            "v-form",
                            { ref: "form" },
                            [
                              _c(
                                "v-layout",
                                { attrs: { wrap: "" } },
                                [
                                  _c(
                                    "v-flex",
                                    { attrs: { xs12: "", "d-flex": "" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          rules: [_vm.rules.required],
                                          clearable: ""
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "label",
                                            fn: function() {
                                              return [
                                                _c("RequiredDot", [
                                                  _vm._v("Subject")
                                                ])
                                              ]
                                            },
                                            proxy: true
                                          }
                                        ]),
                                        model: {
                                          value: _vm.formData.subject,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.formData,
                                              "subject",
                                              $$v
                                            )
                                          },
                                          expression: "formData.subject"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    {
                                      attrs: { xs12: "", sm6: "", "d-flex": "" }
                                    },
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          "item-text": "name",
                                          "item-value": "id",
                                          items: _vm.departmenets,
                                          rules: [_vm.rules.required],
                                          outlined: ""
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "label",
                                            fn: function() {
                                              return [
                                                _c("RequiredDot", [
                                                  _vm._v("Department")
                                                ])
                                              ]
                                            },
                                            proxy: true
                                          }
                                        ]),
                                        model: {
                                          value: _vm.formData.department,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.formData,
                                              "department",
                                              $$v
                                            )
                                          },
                                          expression: "formData.department"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    {
                                      attrs: { xs12: "", sm6: "", "d-flex": "" }
                                    },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          label: "CC",
                                          rules: [_vm.rules.email],
                                          clearable: ""
                                        },
                                        model: {
                                          value: _vm.formData.cc,
                                          callback: function($$v) {
                                            _vm.$set(_vm.formData, "cc", $$v)
                                          },
                                          expression: "formData.cc"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c("Editor", { ref: "editor" })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        [
                          _c(
                            "Upload",
                            {
                              ref: "fileupload",
                              attrs: { size: _vm.limitSize.size },
                              scopedSlots: _vm._u([
                                {
                                  key: "files",
                                  fn: function(ref) {
                                    var files = ref.files
                                    return [
                                      _c("FileList", {
                                        attrs: { files: files },
                                        on: { remove: _vm.removeFile }
                                      })
                                    ]
                                  }
                                }
                              ]),
                              model: {
                                value: _vm.fileList,
                                callback: function($$v) {
                                  _vm.fileList = $$v
                                },
                                expression: "fileList"
                              }
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "ml-0 white--text text-none",
                                  attrs: { dark: "", color: "blue-grey " }
                                },
                                [_vm._v("Attach files")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        [
                          _c(
                            "v-layout",
                            { attrs: { row: "" } },
                            [
                              _c("v-spacer"),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "text-none",
                                  attrs: { color: "blue darken-1 ", flat: "" },
                                  on: {
                                    click: function($event) {
                                      return _vm.close()
                                    }
                                  }
                                },
                                [_vm._v("Discard")]
                              ),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "text-none",
                                  attrs: {
                                    color: "primary",
                                    loading: _vm.loading
                                  },
                                  on: { click: _vm.ok }
                                },
                                [
                                  _vm._v(
                                    "Send\n                                "
                                  ),
                                  _c(
                                    "v-icon",
                                    { attrs: { right: "", dark: "" } },
                                    [_vm._v("send")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }