<template>
    <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition" >
        <v-card  class="new-tt">
            <v-toolbar dark color="primary" height="48" fixed>
                <v-btn icon dark @click="close()">
                    <v-icon>close</v-icon>
                </v-btn>
                <v-toolbar-title>New Ticket</v-toolbar-title>
            </v-toolbar>
            <v-container class="panel-wrapper" grid-list-xl>
                <div style="padding-top: 48px;">
                    <v-layout column>
                        <v-flex>
                            <p>Thank you for contacting Cloud Clusters. Providing adequate information or context will help us investigate your issue faster.</p>
                            <v-divider></v-divider>
                        </v-flex>
                        <v-flex>
                            <v-form ref="form">
                                <v-layout wrap>
                                    <v-flex xs12 d-flex>
                                        <v-text-field
                                                v-model="formData.subject"
                                                :rules="[rules.required]"
                                                clearable>
                                            <template v-slot:label>
                                                <RequiredDot>Subject</RequiredDot>
                                            </template>
                                        </v-text-field>
                                    </v-flex>
                                    <v-flex xs12 sm6 d-flex>
                                        <v-select
                                                v-model="formData.department"
                                                item-text="name"
                                                item-value="id"
                                                :items="departmenets"
                                                :rules="[rules.required]"
                                                outlined>
                                            <template v-slot:label>
                                                <RequiredDot>Department</RequiredDot>
                                            </template>
                                        </v-select>
                                    </v-flex>
                                    <v-flex xs12 sm6 d-flex>
                                        <v-text-field
                                                v-model="formData.cc"
                                                label="CC"
                                                :rules="[rules.email]"
                                                clearable
                                        ></v-text-field>
                                    </v-flex>
                                </v-layout>
                                <Editor ref="editor" ></Editor>
                            </v-form>
                        </v-flex>
                        <v-flex>
                            <Upload :size="limitSize.size"  ref="fileupload"  v-model="fileList">
                                <template v-slot:files="{files}">
                                    <FileList @remove="removeFile" :files="files"></FileList>
                                </template>
                                <v-btn class="ml-0 white--text text-none" dark color="blue-grey ">Attach files</v-btn>
                            </Upload>
                        </v-flex>
                        <v-flex>
                            <v-layout row>
                                <v-spacer></v-spacer>
                                <v-btn color="blue darken-1 " class="text-none" flat @click="close()">Discard</v-btn>
                                <v-btn color="primary" class="text-none" :loading="loading" @click="ok">Send
                                    <v-icon right dark>send</v-icon>
                                </v-btn>
                            </v-layout>
                        </v-flex>
                    </v-layout>
                </div>
            </v-container>
        </v-card>
    </v-dialog>
</template>

<script>
    import Editor from '@/components/Editor.vue'
    import Upload from '@/components/upload/Upload.vue'
    import loadingMixin from '@/module/mixins/loading.mixin.js'
    import FileList from '@/components/upload/FileList.vue'
    import rules from '@/module/rules/index.js'
    import RequiredDot from '@/components/RequiredDot.vue'
    import {limitSize} from '@/module/constant.js'

    let dkeys = ['attachments','department','cc','subject','content']
    let createData  = function( item){
        let obj = {}
        dkeys.forEach((key) => {
            obj[key] =( item && item[key]) ?item[key]:''
            if(key === 'attachments' && !obj[key] ){
                obj[key] = []
            }
        })
        return obj
    }
    export default {
        components: {
            Editor,
            Upload,
            FileList,
            RequiredDot
        },
        computed: {
            attachments (){
                return this.fileList.map( o => o.response.data)
            }
        },
        data() {
            return {
                limitSize: limitSize,
                fileList: [],
                formData: createData(),
                departmenets:[],
                rules: {...rules}
            }
        },
        mixins: [loadingMixin],
        name: "NewTicket",
        watch: {
            dialog(val) {
                if (!val) {
                    this.formData = createData()
                    this.$refs.form.resetValidation()
                    this.$refs.editor.setData('')
                }
            }
        },
        methods: {
            open(val) {
                if (val) {
                    this.formData = createData(val)
                    this.$refs.editor.setData(val.content)
                }
                this.dialog = true
            },
            $validator() {
                let flag = this.$refs.form.validate() && this.$refs.fileupload.getUploaded()
                if(flag){
                    this.createTicketData()
                }
                return flag
            },
            createTicketData() {
                this.formData = Object.assign(this.formData, {
                    content: this.$refs.editor.getData(),
                    attachments: this.attachments
                })
            },
            getDepartments() {
                this.$http.get(`/departments/`).then(res => {
                    this.departmenets = res.results
                }).catch((e) => {
                    this.$message.error(e.detail)
                })
            },
            removeFile(index) {
                this.fileList.splice(index,1)
            }
        },
        created() {
            this.getDepartments()
        },
    }
</script>

<style lang="scss">
    .new-tt{
        .file-uploads-html5{
            text-align: left;
        }
    }
</style>
