<template>
    <div class="upload-list">
        <p class="small-title" slot="list" v-if="files.length" style="margin-bottom: 0;">Attached files:</p>
        <ul >
            <li v-for="(file, index) in files" :key="file.name">
                <v-layout row >
                    <v-flex shrink d-flex align-center>
                        <v-layout style="width: 200px;">
                            <v-flex  xs8 align-self-center>
                                <v-progress-linear
                                        :value="file.progress"
                                        height="5" :color="getStatus(file)"
                                        class="elevation-2 ma-0"></v-progress-linear>
                            </v-flex>
                            <v-flex  xs4 align-self-center>
                                <span style="display: inline-block;padding-left: 10px;">{{ file.progress + '%' }}</span>
                            </v-flex>
                        </v-layout>
                    </v-flex>
                    <v-flex grow align-self-center style="width: 200px">
                        <span style="display: inline-block;padding-left: 20px;" class="file-name" :class="{errormessage:getStatus(file)==='red'}">{{file.name}}</span>
                        <span style="display: inline-block;padding-left: 20px;" v-if="!file.active && !file.success" :class="{errormessage:getStatus(file)==='red'}"> -- {{file.error === 'size'?limitSize.errMsg:file.error}}</span>
                        <v-icon v-if="file.success || file.error"  class="remove-btn" small   @click="removeFile(index)">close</v-icon>
                    </v-flex>
                </v-layout>
            </li>
        </ul>
    </div>
</template>

<script>
    import {limitSize} from '@/module/constant.js'

    export default {
        name: "FileList",
        props: {
            files: Array
        },
        data() {
            return  {
                limitSize: limitSize
            }
        },
        methods: {
            getStatus(file){
                if(!file.active && file.success){
                    //success
                    return 'green'
                }else if(file.active && !file.success){
                    return 'blue'
                }else if(!file.active && !file.success){
                    return 'red'
                }else{
                    return 'blue'
                }
            },
            removeFile(index) {
                this.$emit('remove',index)
            }
        }
    }
</script>

<style lang="scss">
    .upload-list{
        ul {
            list-style: none;
            padding: 0;
            li {
                .remove-btn{
                    display: none;
                    margin: 0 0 0 20px;
                }
                .errormessage{
                    color: #FF5252 ;
                }
                &:hover{
                    .remove-btn{
                        display: inline-block;
                    }
                }
            }
        }
    }

</style>
